<!--
 * @Description: 
 * @Author: leimin
 * @Date: 2022-05-25 10:12:26
 * @LastEditTime: 2023-01-07 22:56:26
 * @LastEditors: leimin
-->
<template>
    <div class="app-container">
        <div class="layout-content">
            <router-view/>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {},
    
}
</script>

<style scoped lang="scss">
.app-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .layout-content {
        flex: 1;
    }
}
</style>
